<template>
  <Popup ref="popupRef" width="500px" title="修正用户信息" @confirm="confirm" @close="resetForm">
    <el-form :model="form" label-width="80px">
      <el-form-item label="修正类型">
        <el-select v-model="form.correctType" placeholder="请选择要修正的类型" style="width: 100%;">
          <el-option
            v-for="item in typeList"
            :key="item.type"
            :label="item.text"
            :value="item.type">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年龄" v-if="form.correctType == 'CORRECT_USER_AGE'">
        <el-date-picker
          v-model="form.age"
          type="date"
          placeholder="选择出生日期"
          style="width: 100%;"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="性别" v-if="form.correctType == 'CORRECT_USER_GENDER'">
        <el-radio-group v-model="form.gender">
          <el-radio label="男">男</el-radio>
          <el-radio label="女">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="原因">
        <el-input v-model="form.reason" type="textarea" rows="5" placeholder="请输入修正原因"></el-input>
      </el-form-item>
    </el-form>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { correctInfoApi } from '@/api/admin.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      uid: '',
      typeList: [
        {text: '年龄', type: 'CORRECT_USER_AGE'},
        {text: '性别', type: 'CORRECT_USER_GENDER'}
      ],
      form: {
        correctType: '',
        age: '',
        gender: '',
        reason: ''
      }
    }
  },
  methods: {
    open(option) {
      console.log('option', option)
      this.uid = option.uid
      if(option.uid && option.resumeDetail) {
        this.form.age = option.resumeDetail.birthday || ''
        this.form.gender = option.resumeDetail.gender || ''
        this.$refs.popupRef.open()
      }
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetForm() {
      this.form = {
        correctType: '',
        age: '',
        gender: ''
      }
    },
    confirm() {
      if(!this.form.correctType) return this.$tips({message: '请选择要修正的类型', type: 'warning'})
      if(!this.form.reason.trim()) return this.$tips({message: '请输入修正原因', type: 'warning'})
      const formData = {
        uid: this.uid,
        correctType: this.form.correctType,
        extParam: {
          reason: this.form.reason.trim()
        }
      }
      if(this.form.correctType == 'CORRECT_USER_AGE') {
        if(!this.form.age) return this.$tips({message: '请选择出生日期', type: 'warning'})
        formData.extParam.birth = this.form.age
      }
      if(this.form.correctType == 'CORRECT_USER_GENDER') {
        if(!this.form.age) return this.$tips({message: '请选择要修正的性别', type: 'warning'})
         formData.extParam.gender = this.form.gender
      }
      console.log('formData', formData)
      correctInfoApi(formData).then(res => {
        this.$tips({message: res.msg, type:'success'})
        this.close()
        this.$emit('update')
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>